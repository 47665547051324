/*===> Begin signup form <===*/
.signup-form {
	display: flex;
	box-shadow: 0px 6px 12px rgba(3, 109, 136, 0.14);
	border-radius: 8px;
	overflow: hidden;
	max-width: 280px;

	input {
		margin-bottom: 0;
	    padding: 16px 17px;
	    border: none;
	    background: #fff;
	}

	button, .button {
		border-radius: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-left: 0;
		}
	}
}
/*===> End signup form <===*/